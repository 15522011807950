<template>
  <div>
      <header>
        <h1 class="header--title">Dashboard</h1>
      </header>

      <div class="flex">

        <div v-if="getClassiWithoutTutor" class="col col-12 col-sm-6 col-md-4">
          <div class="dashboardBox">
              <h3>Classi senza tutor</h3>
              <table v-for="classe in getClassiWithoutTutor" :key="classe.id" class="table">
                  <tr>
                    <td>{{ classe.nome }}</td>
                    <td>{{ classe.scuola.nome }}</td>
                    <td>{{ classe.scuola.comune }}</td>
                    <td><ModificaClasse :classe="classe"/></td>
                  </tr>
              </table>
          </div>
        </div>

        <div v-if="getClassiWithoutTutor" class="col col-12 col-sm-6 col-md-4">
          <div class="dashboardBox">
              <h3>Credenziali non inviate</h3>
              <table v-for="utente in getUtentiCredenzialiNonInviate" :key="utente.id" class="table">
                  <tr>
                    <td>{{ utente.nome }}</td>
                    <td><ModificaUtente :utente="utente"/></td>
                  </tr>
              </table>
          </div>
        </div>

        

      </div>

      <div class="flex">

        <div v-if="getClassiQuizAttivo" class="col col-12 col-sm-6 col-md-4">
          <div class="dashboardBox">
              <h3>Classi Quiz attivo</h3>
              <div v-for="classe in getClassiQuizAttivo" :key="classe.id">
                  {{ classe.nome }}
                  <router-link :to="{ name: 'Classe', params: { classeId: classe.id }}" class="button button--dark button--extrasmall">Entra</router-link>
              </div>
          </div>
        </div>

          <div v-if="getPercorsi" class="col col-12 col-sm-6 col-md-4">
            <div class="dashboardBox">
                <h3>Percorsi</h3>
                
            </div>
          </div>
      </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import ModificaClasse from '@/components/editor/ModificaClasse.vue'
import ModificaUtente from '@/components/editor/ModificaUtente.vue'

import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  name: 'EditorDashboard',

  data: () => {
    return {
      timer_dashboard: ''
     //classi: []
    }
  },

  components: {
    ModificaClasse,
    ModificaUtente
  },

   computed: {

    ...mapGetters('istituti',[
        'getIstituti'
     ]),

      ...mapGetters('percorsi',[
        'getPercorsi',
     ]),

     ...mapGetters('classi',[
        'getClassi',
        'getClassiWithoutTutor',
        'getClassiQuizAttivo',
        'getClassiWithoutPercorso'
     ]),

     ...mapGetters('utenti',[
        'getUtentiCredenzialiNonInviate'
     ]),  

  },

  methods: {
    ...mapActions('classi',[
        'fetchClassi',
    ]),

    ...mapActions('percorsi',[
        'fetchPercorsi',
    ]),

    ...mapActions('utenti',[
        'fetchUtenti',
    ]),

    refreshDashboard() {  
        this.$store.dispatch('percorsi/fetchPercorsi')
        this.$store.dispatch('classi/fetchClassi')
    }
    
  },

  beforeCreate() {
    this.$nextTick().then(() => document.body.className = '')
  },

  beforeMount() {
   this.$store.dispatch('percorsi/fetchPercorsi')
   this.$store.dispatch('classi/fetchClassi')
   this.$store.dispatch('utenti/fetchUtenti')
  },

  mounted() {
    this.timer_dashboard =  window.setInterval(() => {
        this.refreshDashboard()
      }, 20000) 
  },

  destroyed: function() {
    clearInterval(this.timer_dashboard)
  }

}
</script>
